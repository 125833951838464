import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100vw",
  zIndex: -1,
  maxWidth: "1440px",

  backgroundRepeat: "no-repeat",
  padding: "1.5em 0 0 0em",
  "@media only screen and (max-width : 1000px)": {},
});

export const Box = styled("div", {
  display: "flex",
  width: "80vw",
  margin: "2rem 0",
  maxWidth: "inherit",
  gap: "18em",
  "@media only screen and (max-width : 1000px)": {
    flexDirection: "column",
    alignItems: "center",
  },
  "@media only screen and (max-width : 600px)": {
    width: "90vw",
    gap: "1em",
  },
});
export const Content = styled("div", {
  width: "50%",
  padding: "0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  objectFit: "cover",
  maxWidth: "600px",
  "@media only screen and (max-width : 1000px)": {
    padding: "1rem",
    width: "100%",
    marginTop: "1em",
    alignItems: "flex-start",
    textAlign: "justify",
  },
});

export const OffsetContent = styled("div", {
  //   border: "1px solid red",
  width: "50%",
  marginTop: "5em",
  background: "rgba(255, 255, 255, 0.8)",
  maxWidth: "500px",
  "@media only screen and (max-width : 1000px)": {
    width: "100%",
    marginTop: "2em",
    alignItems: "flex-start",
    textAlign: "justify",
  },
});

export const Image = styled("img", {
  width: "100%",
  "@media only screen and (max-width : 600px)": {
    width: "100%",
  },
});

export const Heading = styled("h1", {
  padding: "2em 1em 0 0.5em",
  fontFamily: "$primary",
  "@media only screen and (max-width : 600px)": {
    padding: "0 1em 0 0.5em",
    fontSize: "$8",
  },
});

export const Para = styled("p", {
  fontFamily: "$secondary",
  padding: "0 1em",
  lineHeight: "32px",
  margin: "2rem 0",
  color: "$bodyColor",
});
