import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100vw',
  zIndex: -1,
  padding: '2em 10em',
  marginTop: '4em',
  display: 'none',
  '@media only screen and (max-width : 1000px)': {
    padding: '2em',
    marginTop: '-2em',
    flexDirection: 'column',
    display: 'block',
  },

  '@media only screen and (max-width : 600px)': {
    padding: '2em 1.2em',
  },
});

export const Box = styled('div', {
  display: 'flex',
  marginTop: '3em',
  alignItems: 'center',
  '@media only screen and (max-width : 1000px)': {
    flexDirection: 'column',
    marginTop: '3em',
  },
});
export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'top',
  alignItems: 'flex-start',
  padding: '1em 1em 0 1em',
  width: '95%',

  '@media only screen and (max-width : 1000px)': {
    height: 'initial',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export const Para = styled('p', {
  fontSize: '$3',
  lineHeight: '2em',
  width: '80%',
  fontFamily: '$secondary',
  color: '$secondary',
  '@media only screen and (max-width : 1000px)': {
    width: '100%',
    fontSize: '$2',
  },
});

export const Header = styled('h1', {
  fontSize: '60px',
  lineHeight: '60px',
  width: '80%',
  marginBottom: '0.5em',
  fontFamily: '$font',
  '@media only screen and (max-width : 600px)': {
    fontSize: '32px',
  },
  color: '$font',
});

export const Image = styled('img', {
  '@media only screen and (max-width : 1000px)': {
    width: '80vw',
  },
});

export const Button = styled('a', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$buttonBackground',
  fontSize: '$2',
  fontWeight: '600',
  height: '3em',
  padding: '0.5em 1.5em',
  margin: '3em 0',
  borderRadius: '40px',
  border: 'none',
  color: '$white',
  textDecoration: 'none',
  '&:hover': {
    color: '$white',
  },
});
