import * as S from './Artisans.style.js';
// import Desc_1 from "./../../assets/Desc_1.png";
// import Desc_2 from "./../../assets/Desc_2.png";
// import Desc_3 from "./../../assets/Desc_3.png";
// import Desc_4 from "./../../assets/Desc_4.png";

import Animation from 'react-animate-on-scroll';

const Artisans = (data) => {
  return (
    <S.Container>
      <S.Box>
        <S.Content
          style={{
            backgroundImage: 'url("' + data.artisans.backgroundImage + '")',
          }}
        >
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={data.artisans.foregroundImage} />
          </Animation>
        </S.Content>
        <S.OffsetContent>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Heading>{data.artisans.heading} </S.Heading>
            <S.Para>{data.artisans.mainText}</S.Para>
          </Animation>
        </S.OffsetContent>
      </S.Box>
    </S.Container>
  );
};

export default Artisans;
