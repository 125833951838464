import Logo from './assets/logo.png';
import Background from './assets/Hero_Background.png';
import Hero_Foreground from './assets/Hero_Foreground.png';
import Product_1 from './assets/Product_1.png';
import Product_2 from './assets/Product_2.png';
import Product_3 from './assets/Product_3.png';
import Product_4 from './assets/Product_4.png';
import AllProducts from './assets/AllProducts.jpg';

import About_1_Background from './assets/About_1_Background.png';
import About_1_Foreground from './assets/About_1_Foreground.png';
import Description_1_Background from './assets/Description_1_Background.png';
import Description_1_Foreground from './assets/Description_1_Foreground.png';
import Sustainability_1_Background from './assets/Sustainability_1_Background.png';
import Sustainability_1_Foreground from './assets/Sustainability_1_Foreground.png';
import Artisan_Foreground from './assets/Artisans_foreground.png';
import Artisan_Background from './assets/Artisans_background.png';

// import Product_6 from './assets/Product_6.png';
// import Product_7 from './assets/Product_7.png';
// import Product_8 from './assets/Product_8.png';
// import Product_9 from './assets/Product_9.png';
// import Product_10 from './assets/Product_10.png';

import Certification_1 from './assets/Certifications_1.png';
import Certification_2 from './assets/Certifications_2.png';
import Certification_3 from './assets/Certifications_3.png';

import Certification_4 from './assets/Certifications_4.png';
import Certification_5 from './assets/Certifications_5.png';
import Certification_6 from './assets/Certifications_6.png';

import About_1 from './assets/About_1.png';
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from './assets/artisans.png';
import sustainability from './assets/sustainability.png';

const data = {
  companyName: 'A Chic Couture',
  mainText: 'Shop for dressing up for a party',
  subText: '',
  productSectionTitle: 'Our Products',
  brandsTitle: 'Brands we work with',
  certificationsTitle: 'Compliances and Certifications',
  buttonContent: 'Send an enquiry',
  logo: Logo,
  background: Background,
  foreground: Hero_Foreground,
  about: {
    heading: 'About us',
    mainText:
      'Founder, Designer and Managing Director; Mr. Anuraag Chauhan has been working since 1989 in Delhi and New York. Selling to chain stores and Designers. After 9/11 he switched his base from U.S. to Europe. Now, he is working and creating the contemporary designs which are for our Indian market and European market which are pocket friendly for a commoner and an exclusive range for the high end customers too.',
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: 'Vision',
    mainText: `We look forward to cater to the huge Indian market which is economically strengthening, eager to the new fashion of India and International trends.`,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
  },
  artisans: {
    heading: 'Artisans',
    mainText:
      'We are a company designing, manufacturing and exporting western party dresses which are sequinned and embroidered. It s been almost 20 years that we are exporting to U.S. and France. Mostly all our designs are created in house by our designers therefore they are exclusive and fresh for the market. We have a small skilled team of beaders, tailors, checkers and finishers who take the utmost care in producing new samples for our upcoming collections..',
    foregroundImage: Artisan_Foreground,
    backgroundImage: Artisan_Background,
  },
  sustainability: {
    heading: 'Sustainability',
    mainText:
      'We are dedicated to producing and delivering high-quality items to our consumers. Our goal is to satisfy the needs of our customers on time and to strive for continual development. Our products are one-of-a-kind because of the long hours of effort, combined with a humble desire to deliver the best to our customers all around the world',
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: 'G-1201 IVORY No.25',
      link: '/#',
    },
    {
      title: 'D-191 BLK SIL No.39',
      link: '',
    },
    {
      title: 'G-2102 BLK BLK D.COPPER No.18',
      link: '',
    },
    {
      title: 'DZM-26 BLK BLK TEAL No. 10',
      link: '',
    },
  ],

  brandArray: [
    {
      title: '1/1 - Khirki Village, Malviya Nagar, New Delhi – 110017',
      link: '#',
      // image: Background,
    },
    // {
    //   title: 'Company 2',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 3',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 4',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 5',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 6',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 7',
    //   link: '/#',
    //   image: Background,
    // },
    // {
    //   title: 'Company 8',
    //   link: '/#',
    //   image: Background,
    // },
  ],
  prodArray: [
    {
      title: 'G-1201 IVORY',
      image: Product_1,
      link: 'https://catalogue.sourcewiz.com/A-CHIC-COUTURE/jvxrdlw',
    },
    {
      title: 'D-191 BLK SIL',
      image: Product_2,
      link: 'https://catalogue.sourcewiz.com/A-CHIC-COUTURE/jvxrdlw',
    },
    {
      title: 'G-2102 BLK BLK D.COPPER',
      image: Product_3,
      link: 'https://catalogue.sourcewiz.com/A-CHIC-COUTURE/jvxrdlw',
    },
    {
      title: 'DZM-26 BLK BLK TEAL',
      image: Product_4,
      link: 'https://catalogue.sourcewiz.com/A-CHIC-COUTURE/jvxrdlw',
    },
    {
      title: 'D-STONE BLK BLK',
      image: AllProducts,
      link: 'https://catalogue.sourcewiz.com/A-CHIC-COUTURE/1ugz9x7',
    },
    // {
    //   title: "Product 6",
    //   image: Product_6,
    //   link: "",
    // },
    // {
    //   title: "Saffire",
    //   image: Product_7,
    //   link: "",
    // },
    // {
    //   title: "Pearl",
    //   image: Product_8,
    //   link: "",
    // },
    // {
    //   title: "Mamluks",
    //   image: Product_9,
    //   link: "",
    // },
    // {
    //   title: "Cyprus",
    //   image: Product_10,
    //   link: "",
    // },
  ],
  imgArray: [
    {
      image: Certification_1,
    },
    {
      image: Certification_2,
    },
    {
      image: Certification_3,
    },
    // {
    //   image: Certification_4,
    // },
    // {
    //   image: Certification_5,
    // },
    // {
    //   image: Certification_6,
    // },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: 'Sustainability is the way of life, the way of our manufacturing',
    text: '',
    image: sustainability,
  },

  artisansData: {
    title:
      'The artisans are the masters behind creating our world class products',
    text: '',
    image: artisans,
  },

  emails: ['ap3919529@gmail.com'],
};

export default data;
