import { styled } from './../../styles/theme.config.js';

export const Nav = styled('nav', {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999999,
  top: 0,
  left: 0,
  height: '5em',
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  '@media only screen and (min-width : 1025px)': {
    left: 'auto',
    padding: '0 0 0 10px',
  },
});

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '95vw',

  '@media only screen and (max-width : 600px)': {
    width: '85%',
    justifyContent: 'space-between',
  },
});

export const Icon = styled('div', {
  fontSize: '$5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Header = styled('h2', {
  color: 'white',
  fontSize: '$4',
  margin: '0.5em',
  fontFamily: '$primary',
  '@media only screen and (max-width : 600px)': {
    fontSize: '$2',
  },
});

export const LinkContainer = styled('div', {
  display: 'flex',
  '@media only screen and (max-width : 1000px)': {
    display: 'none',
  },
});

export const Link = styled('a', {
  color: '$font',
  fontSize: '$2',
  textDecoration: 'none',
  padding: '2em',
  cursor: 'pointer',
  fontFamily: 'DM Sans',
  '&:hover': {
    color: '$secondary',
  },
  '&:hover div': {
    display: 'block',
  },
});

export const ContactButton = styled('a', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  fontSize: '$2',
  // fontWeight: "600",
  height: '3em',
  fontFamily: 'DM Sans',
  padding: '0.5em 1.5em',
  margin: '1em',
  borderRadius: '4px',
  border: '1px solid $font',
  color: '$font',
  borderRadius: '40px',
  textDecoration: 'none',
  '&:hover': {
    color: '$secondary',
    background: 'transparent',
  },
});

export const MenuContainer = styled('div', {
  position: 'fixed',
  zIndex: 99999,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  // background: '#2A2F3440',
  transform: 'translateY(5em)',
});

export const Menu = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  height: '100vh',
  width: '300',
  zIndex: 99999,
  background: '$white',
  padding: '0.5em 0 1.5em 0',
});

export const MenuLink = styled('a', {
  color: '$font',
  fontFamily: 'DM Sans',
  fontSize: '$3',
  textDecoration: 'none',
  margin: '0.5em 1.5em',
  width: '180px',
  fontFamily: '$primary',
});

export const MenuButton = styled('button', {
  display: 'none',
  background: 'none',
  fontFamily: 'DM Sans',
  border: 'none',
  '@media only screen and (max-width : 1000px)': {
    display: 'block',
  },
});

export const Cross = styled('button', {
  background: 'none',
  border: 'none',
  textAlign: 'right',
  padding: '0 0.5em',
});

export const Image = styled('img', {
  width: '40px',
});
